<!-- Localized -->
<template>
    <FormulateForm #default="{ hasErrors }">
        <div class="flex flex-1 gap-6 items-center">
            <div class="flex gap-6 flex-1">
               <FormulateInput :disabled="!checkPermission('role.edit')" class="w-full" :label="$t('components.roles_permissions.role_name')" :placeholder="$t('components.roles_permissions.role_name')" type="text" validation="required|matches:/^[a-zA-Z0-9\s]+$/|max:24,length" :validation-messages="{ matches: $t('components.roles_permissions.error_only_numbers_alphabets') }" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" v-model="roleInfo.name" />

                <FormulateInput :disabled="!checkPermission('role.edit')" class="w-full" :label="$t('components.roles_permissions.role_description')" :placeholder="$t('components.roles_permissions.role_description')" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" v-model="roleInfo.description" />
            </div>
            <div class="flex gap-2" v-if="checkPermission('role.delete') || checkPermission('role.edit')">
                <div class="w-10 h-10" v-allow="'role.edit'">
                    <button :disabled="loadingStates.updateRole || !roleInfo.name || hasErrors" @click="$emit('updateRole')" class="h-10 w-10 mt-1 bg-white text-primary flex justify-center rounded items-center disabled:opacity-50">
                        <font-awesome-icon icon="save" />
                    </button>
                </div>
                <div class="w-10 h-10" v-allow="'role.delete'">
                    <button :disabled="loadingStates.deleteRole" @click="$emit('deleteRole')" class="h-10 w-10 mt-1 bg-white flex justify-center rounded items-center disabled:opacity-50">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-red-600 hover:text-red-700 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </FormulateForm>
</template>

<script>
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "permissions-info",
    components: {},
    props: {
        roleInfo: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {},
    async mounted() {},
    methods: {
        checkPermission,
    },
};
</script>

<style lang="scss" scoped></style>
