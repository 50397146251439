export const makeFilterParams = (serverParams) => {
  const filterParams = {};

  if (serverParams) {
    Object.keys(serverParams).map((key) => {
      if (key == 'order_date' || key == 'sale_date') {
        filterParams[key] = serverParams[key] ? serverParams[key]["label"] : delete filterParams[key];
      } else {
        filterParams[key] = serverParams[key] ? serverParams[key]["name"] : delete filterParams[key];
      }
      if (serverParams[key]["name"] === undefined) {
        delete filterParams[key];
      }
    });
  }
  return filterParams;
};

export const makeQueryParams = (serverParams, currentPageData) => {
  const filterParams = makeFilterParams(serverParams);
  return filterParams
    ? new URLSearchParams({...currentPageData, ...filterParams})
    : new URLSearchParams(currentPageData);
};

export const getPermissions = (data) => {
  const result = [];
  data.forEach((el) =>
    el.permissions.forEach((per) => {
      if (per.selected) {
        result.push(per);
      }
    })
  );
  return result;
};

export const selectAllChildPermissions = (permissionsList, { input, index, all }) => {
  return permissionsList.map((el, id) => {
    if (all) {
      return {
        ...el,
        ...(id === index ? { permissions: el.permissions.map((per) => ({ ...per, selected: input })) } : {})
      }
    }

    const permissionsCheckedCount = el.permissions.filter((el) => el.selected).length;
    return {
      ...el,
      selected: permissionsCheckedCount === el.permissions.length
    }
  })
};
