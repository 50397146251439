import { adminList } from "../utils/constants";

const apiUrlPrefixConfig = Object.freeze({
  [adminList.TENANT]: () => "tenant-role/", // default
  [adminList.CLIENT]: (payload) => `client-role/${payload.client_id}/`,
});

export const getApiUrlPrefix = (adminPanel, payload = {}) => {
  const getUrlFunc = apiUrlPrefixConfig[adminPanel] || apiUrlPrefixConfig[adminList.TENANT];
  return getUrlFunc(payload);
};
